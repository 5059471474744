import React from 'react'
import './about.css'
import ME from '../../assets/IMG_3242.jpeg'
import {IoMdSchool} from 'react-icons/io'
import {GiDiploma} from 'react-icons/gi'
import {FaAward} from 'react-icons/fa'

const About = () => {
  return (
    <section id='about'>
      <h3>If you want to learn</h3>
      <h2 className='about-header'>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Image" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <IoMdSchool className='about__icon' />
              <h5>Education</h5>
              <small>University of Michigan</small>
            </article>

            <article className='about__card'>
              <GiDiploma className='about__icon' />
              <h5>Degree</h5>
              <small>BS Computer Science</small>
            </article>

            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>1 year working</small>
            </article>
          </div>

          <p>
          I graduated from Michigan with a BS in Computer Science and I have a genuine love for problem-solving through coding. 
          With a solid academic background and hands-on experience gained through internships, 
          I thrive on turning ideas into reality through efficient software solutions. 
          </p>
          <p>
          When I'm not programming, I'm either watching or playing sports, or listening to music - maybe both!
          Whether it's cheering for my favorite team or exploring new sound, my hobbies keep me energized and inspired. 
          Delve into my portfolio and discover the exciting projects I've been a part of.
          </p>

          <a href="#contact" className='btn btn-primarry'>Let's Talk</a>
        </div>
      </div>
    </section>
  )
}

export default About