import React from 'react'
import Popup from './Popup'
import './portfolio.css'

import { useState } from 'react'

import IMG1 from '../../assets/pickem.png'
import IMG2 from '../../assets/Launchpad logo.png'
import IMG3 from '../../assets/white_logo.png'
import IMG4 from '../../assets/itunes.png'
import IMG5 from '../../assets/asteroid.png'
import IMG6 from '../../assets/citybike.png'

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'NFL Pick \'Em',
    description: 'A fantasy football web app. Feel free to make an account and compete. Pick 2 teams per week, only choosing 4 duplicates over the entire season. You earn the amount of points your team wins by, and you lose the amount of points they lose by.',
    link: 'https://nfl-pickem.netlify.app/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Launchpad',
    description: 'An all-in-one platform for college students to search for internships and full time roles. Get resume tips, interview practice, and more!',
    link: 'https://www.youtube.com/watch?v=PmUQDUjuNvs'
  },
  {
    id: 3,
    image: IMG3,
    title: 'LettuceEat',
    description: 'A food social media app, aimed at the vegan community. Enter the ingredients that you have in your fridge for customized vegan recipes. If you don\'t feel like cooking, well suggest restaurants with vegan options in your area.',
    link: 'https://www.youtube.com/watch?v=46_hU4VaN80&t=2s'
  },
  {
    id: 4,
    image: IMG4,
    title: 'iTunes Search Engine',
    description: 'Enter the name of an artist and press enter. That\'s all there is to it. Tons of information is shown, and can be sorted and filtered in multiple ways. If you like music, this app\'s for you.',
    link: 'https://itunes-search-engine.netlify.app'
  },
  {
    id: 5,
    image: IMG5,
    title: 'Asteroids',
    description: 'Feeling nostalgic? Play this classic asteroid game from your desktop to bring back some good memories.',
    link: 'https://asteroids-jts.netlify.app'
  },
  {
    id: 6,
    image: IMG6,
    title: 'CityBike Analysis',
    description: 'An in-depth review of the best cities to bike in the world, if you don\'t alrready own a bike. If you want to get started, or see if your city made the cut, check it out.',
    link: 'https://github.com/jshef16/SI-206-Final-Project'
  },
]


const Portfolio = () => {
  const [popupTriggers, setPopupTriggers] = useState(data.map(() => false));


  return (
    <section id='portfolio'>
      <h3>Check out my recent work in</h3>
      <h2 className='portfolio-header'>My Portfolio</h2>

      <div className="container portfolio__container">
        {data.map(({ id, image, title, description, link }, index) => (
          <article key={id} className='portfolio__item'>
            <div className="portfolio__item-image">
              <img src={image} alt={title} />
            </div>
            <h3>{title}</h3>
            <div className="portfolio__item-cta">
              <button className='btn' onClick={() => {
                // Set the corresponding popup trigger to true when the button is clicked
                const updatedTriggers = [...popupTriggers];
                updatedTriggers[index] = true;
                setPopupTriggers(updatedTriggers);
              }}>
                Info
              </button>
              <Popup trigger={popupTriggers[index]} setTrigger={(value) => {
                // Set the corresponding popup trigger to the given value when the popup is closed
                const updatedTriggers = [...popupTriggers];
                updatedTriggers[index] = value;
                setPopupTriggers(updatedTriggers);
              }}>
                <h2>{title}</h2>
                <p>{description}</p>
              </Popup>
              <a href={link} className='btn btn-primary' target='_blank'>
                View
              </a>
            </div>
          </article>
        ))}
      </div>
    </section>
  );
};

export default Portfolio;
