import React from 'react'
import './footer.css'

import {FiInstagram} from 'react-icons/fi'
import {IoLogoTwitter} from 'react-icons/io'
import {BsLinkedin} from 'react-icons/bs'


const Footer = () => {
  return (
    <footer>
      <h1 className='footer__logo'>Jordan Shefman</h1>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.instagram.com/jshef.16/"><FiInstagram/></a>
        <a href="https://twitter.com/BEanalytics_"><IoLogoTwitter/></a>
        <a href="https://www.linkedin.com/in/jordan-shefman/"><BsLinkedin/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Jordan Shefman. All rights reserved.</small>
      </div>
    </footer>

    
  )
}

export default Footer