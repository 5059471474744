import React from 'react'
import './contact.css'

import { useRef } from 'react';
import emailjs from 'emailjs-com';

import {MdOutlineEmail} from 'react-icons/md'
import {BsTwitter} from 'react-icons/bs'
import {ImWhatsapp} from 'react-icons/im'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1sttg22', 'template_kp09hds', form.current, 'Si2geNhZJac515FRh')
      .then((result) => {
          console.log(result.text);
          alert('Message sent successfully')
      }, (error) => {
          console.log(error.text);
      });
    e.target.reset()
  };

  return (
    <section id='contact'>
      <h3>See what I'm up to</h3>
      <h2 className='contact-header'>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>jtshef16@gmail.com</h5>
            <a href="mailto:jtshef16@gmail.com" target='_blank'>Send a message</a>
          </article>

          <article className='contact__option'>
            <BsTwitter className='contact__option-icon'/>
            <h4>Twitter</h4>
            <h5>@BEanalytics_</h5>
            <a href="https://twitter.com/messages/compose?recipient_id=1656741731570794499" className="twitter-dm-button" data-screen-name="@BEanalytics_" target='_blank'>Send a message</a>
          </article>

          <article className='contact__option'>
            <ImWhatsapp className='contact__option-icon'/>
            <h4>WhatsApp</h4>
            <h5>+12489128335</h5>
            <a href="https://api.whatsapp.com/send?=+12489128335" target='_blank'>Send a message</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Your Full Name' required />
          <input type="email" name='email' placeholder='Your Email' required />
          <textarea name="message"  rows="7" placeholder='Your Message' required></textarea>
          <button type='submit' className='btn btn-primary'>Send Message</button>
        </form>
      </div>
    </section>
  )
}

export default Contact