import React from 'react'
import {BsLinkedin} from 'react-icons/bs'
import {FaGithub} from 'react-icons/fa'
import {FaTwitter} from 'react-icons/fa'

const HeaderSocials = () => {
  return (
    <div className='header__socials'>
        <a href="https://www.linkedin.com/in/jordan-shefman/" target='_blank'><BsLinkedin/></a>
        <a href="https://github.com/jshef16" target='_blank'><FaGithub/></a>
        <a href="https://twitter.com/BEanalytics_" target='_blank'><FaTwitter/></a>
    </div>
  )
}

export default HeaderSocials